<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container
      fluid
    >
      <v-row>
        <v-col
          v-if="showSendScrapButton"
          cols="3"
        >
          <v-btn
            block
            color="cyan darken-4"
            height="150"
            class="text-h3"
            @click="onNewWasteSent"
          >
            <v-icon
              x-large
              class="pr-3"
            >
              mdi-recycle-variant
            </v-icon>
            {{ $t('buttondashboard-SendWaste') }}
          </v-btn>
        </v-col>
        <v-col
          v-if="showAssignCommitmentButton"
          cols="3"
        >
          <v-btn
            block
            color="deep-purple darken-4"
            height="150"
            class="text-h3"
            @click="onNewAssignCommitment"
          >
            <v-icon
              x-large
              class="pr-3"
            >
              mdi-file-move-outline
            </v-icon>
            {{ $t('buttondashboard-AssignCommitment') }}
          </v-btn>
        </v-col>
        <v-col
          v-if="showShipProductButton"
          cols="3"
        >
          <v-btn
            block
            :color="pagestyles.shipbutton"
            height="150"
            class="text-h3"
            @click="onNewShipProduct"
          >
            <v-icon
              x-large
              class="pr-3"
            >
              mdi-ferry
            </v-icon>
            {{ $t('buttondashboard-ShipProduct') }}
          </v-btn>
        </v-col>
        <v-col
          v-if="showRequestProductButton"
          cols="3"
        >
          <v-btn
            block
            color="blue darken-4"
            height="150"
            class="text-h3"
            @click="onNewRequestProduct"
          >
            <v-icon
              x-large
              class="pr-3"
            >
              mdi-text-box-check
            </v-icon>
            {{ $t('buttondashboard-RequestProduct') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="showPurchaseCommitment"
          cols="12"
          lg="12"
          md="12"
          sm="12"
        >
          <commitment-summary
            :type="commitmentPurchase"
          />
        </v-col>
        <v-col
          v-if="showSalesCommitment"
          cols="12"
          lg="12"
          md="12"
          sm="12"
        >
          <commitment-summary
            :type="commitmentSales"
          />
        </v-col>
        <v-col
          v-if="showWasteAggregator"
          cols="12"
          lg="12"
          md="12"
          sm="12"
        >
          <commitment-summary
            :type="commitmentWaste"
          />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          md="12"
          sm="12"
        >
          <transactions />
        </v-col>
      </v-row>
    </v-container>
    <error-dialog
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import customColors from '@/styles/appcolors'
  export default {
    name: 'ApplicationDashboard',
    components: {
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
      Transactions: () => import('./components/Transactions'),
      CommitmentSummary: () => import('./components/CommitmentSummary'),
    },
    props: {
      type: {
        type: String,
        default: '0',
      },
    },
    data: function () {
      return {
        breadcrumbs: [
          {
            text: this.$t('bcHome'),
            disabled: false,
            href: '/',
          },
          {
            text: this.$t('bcDashboard'),
            disabled: true,
            href: '/dashboard',
          },
        ],
        pagestyles: {
          background: customColors.Dashboard.background,
          shipbutton: customColors.Dashboard.shipbutton,
        },
        showErrorDialog: false,
        errorMessage: '',
        environment: process.env.VUE_APP_ENVIRONMENT,
        modelProcessing: false,
        dashboardType: 0,
        availableQuantity: 0,
        commitmentPurchase: 1,
        commitmentSales: 2,
      }
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
        clientIPAddress: 'clientIPAddress',
        purchaseSummary: 'dashboardData.purchaseCommitment',
        salesSummary: 'dashboardData.salesCommitment',
        wasteSummary: 'dashboardData.wasteCommitment',
      }),
      appTitle: function () {
        if (this.currentUser != null) {
          return this.currentUser.partnerName + ' - ' + this.$t('titleDashboard')
        } else {
          return this.$t('titleDashboard')
        }
      },
      showSendScrapButton: function () {
        if (this.dashboardType === 2 || this.dashboardType === 3) {
          return true
        } else {
          return false
        }
      },
      showShipProductButton: function () {
        if (this.dashboardType === 1 || this.dashboardType === 2) {
          return true
        } else {
          return false
        }
      },
      showRequestProductButton: function () {
        if (this.dashboardType === 2) {
          return true
        } else {
          return false
        }
      },
      showAssignCommitmentButton: function () {
        if (this.dashboardType === 2) {
          return true
        } else {
          return false
        }
      },
      showPurchaseCommitment: function () {
        let show = false
        if (this.purchaseSummary !== undefined) {
          if (this.purchaseSummary.length !== 0) {
            show = true
          }
        }
        return show
      },
      showSalesCommitment: function () {
        let show = false
        if (this.salesSummary !== undefined) {
          if (this.salesSummary.length !== 0) {
            show = true
          }
        }
        return show
      },
      showWasteAggregator: function () {
        let show = false
        switch (this.dashboardType) {
          case 1:
            if (this.wasteSummary !== undefined) {
              if (this.wasteSummary.length !== 0) {
                show = true
              }
            }
            break
          case 3:
            if (this.wasteSummary !== undefined) {
              if (this.wasteSummary.length !== 0) {
                show = true
              }
            }
            break
          default:
            break
        }
        return show
      },
      commitmentWaste: function () {
        if (this.dashboardType === 1) {
          return 4
        } else {
          return 3
        }
      },
    },
    mounted: function () {
      if (this.currentUser === null) {
        this.modelProcessing = true
        if (this.clientIPAddress === '') {
          this.$store.dispatch('GET_CLIENT_IP')
            .then(response => {
              const currentUserData1 = {
                ClientIPAddress: this.clientIPAddress,
                ClientBrowser: this.$browserDetect.meta.name + '(version: ' + this.$browserDetect.meta.version + ')',
              }
              this.$store.dispatch('GET_CURRENT_USER', currentUserData1)
                .then(response => {
                  this.getDashboardData()
                })
                .catch(error => {
                  this.showErrorDialog = true
                  this.errorMessage = error.message
                  this.modelProcessing = false
                })
            })
        } else {
          const currentUserData = {
            ClientIPAddress: this.clientIPAddress,
            ClientBrowser: this.$browserDetect.meta.name + '(version: ' + this.$browserDetect.meta.version + ')',
          }
          this.$store.dispatch('GET_CURRENT_USER', currentUserData)
            .then(response => {
              this.getDashboardData()
            })
            .catch(error => {
              this.showErrorDialog = true
              this.errorMessage = error.message
              this.modelProcessing = false
            })
        }
      } else {
        this.getDashboardData()
      }
    },
    methods: {
      getDashboardData: function () {
        this.modelProcessing = true
        if (this.type === '0') {
          this.dashboardType = this.currentUser.dashboardType
        } else {
          this.dashboardType = Number(this.type)
        }
        this.dashboardType = this.currentUser.dashboardType
        this.$store.dispatch('GET_DASHBOARD_DATA')
          .then(response => {
            this.modelProcessing = false
          })
          .catch(error => {
            this.showErrorDialog = true
            this.errorMessage = error.message
            this.modelProcessing = false
          })
      },
      onNewWasteSent: function () {
        if (this.dashboardType === 3) {
          this.$router.push('/material/movement/14')
        } else {
          this.$router.push('/material/movement/12')
        }
      },
      onNewShipProduct: function () {
        this.$router.push('/material/movement/1')
      },
      onNewRequestProduct: function () {
        this.$router.push('/material/movement/21')
      },
      onNewAssignCommitment: function () {
        this.$router.push('/material/movement/31')
      },
    },
  }
</script>
