export default {
  MainPage: {
    background: '#00867c',
    textheading: '#ffffff',
    subheading: '#000000',
    breadcrumb: 'display-1 font-weight-bold',
    breadcrumbColor: '#00867c',
  },
  ViewPage: {
    background: '#bcbec0',
  },
  ProfileDrawer: {
    background: '#F0AA00',
  },
  Dashboard: {
    shipbutton: '#BED732',
  },
  Transaction: {
    textheading: '#00867c',
    detailFrameColor: '#9ac2b9',
    detailFrameHeading: '#808285',
    documentFrameColor: '#68c8c6',
    valueChainFrameColor: '#0b3b60',
    valueChainAddColor: '#BED732',
    valueChainItemColor: '#16517e',
    commitmentFrameColor: '#005978',
    commitmentTableColor: '#007299',
  },
  MaterialRecent: {
    pageFrameColor: '#80A1B6',
  },
}
